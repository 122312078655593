import { useCallback, useEffect, useState } from "react"
import { debounce } from "lodash-es"

export const useDebounceEdit = <T>(givenValue: T, remoteMutation: (v: T) => void) => {
    const [value, setValue] = useState(givenValue)

    const handleSave = useCallback(
        debounce((value: T) => {
            setValue(value)
            remoteMutation(value)
        }, 350),
        [remoteMutation]
    )

    useEffect(() => {
        if (givenValue) {
            setValue(givenValue)
        }
    }, [givenValue])

    return {
        value,
        handleSave: (v: T) => { setValue(v); handleSave(v) },
    }
}
