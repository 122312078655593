'use client'
import { useWindowSize } from '@planda/design-system'
import { Button, Flex, Heading, IconButton } from '@radix-ui/themes'
import cs from 'classnames'
import NextLink from 'next/link'
import Link from 'next/link'
import { signOut, useSession } from 'next-auth/react'
import { AiOutlineSchedule } from 'react-icons/ai'
import { FaRegEdit, FaSignOutAlt } from 'react-icons/fa'
import SettingsInputs from '../SettingsInputs'

// TODO: can be converted to server component with media queries
export const Header = () => {
  const { isMobile } = useWindowSize('mobile')
  const { status } = useSession()
  const notSignedIn = status === 'unauthenticated'

  return (
    <header
      className={cs('block shadow-sm sticky top-0 dark:shadow-gray-500 py-3 px-4 z-20')}
      style={{ backgroundColor: 'var(--color-background)' }}
    >
      <Flex align="center" gap="3">
        <AiOutlineSchedule />
        <NextLink href="/">
          <Heading as="h2" size="4" style={{ maxWidth: 200 }}>
            Ethospire
          </Heading>
        </NextLink>
        <sub className='hidden md:block' >Scheduling made human</sub>
        <div className="ml-auto"></div>
        {notSignedIn && <Link href="/api/auth/signin">
          <Button variant={'soft'} >Sign in</Button>
        </Link>}
        {/* {!isMobile && } */}
        <SettingsInputs className='hidden md:flex' />
        {/* {!isMobile && (
          <Tooltip content="Navigation">
            <IconButton
              size="3"
              variant="ghost"
              color="gray"
              className="md:hidden"
              onClick={onToggleSidebar}
            >
              <HamburgerMenuIcon width="16" height="16" />
            </IconButton>
          </Tooltip>
        )} */}
        {isMobile && (
          <Link href={'/chat/calendar'}>
            <IconButton>
              <FaRegEdit />
            </IconButton>
          </Link>
        )}
        {status === 'authenticated' && (
          <IconButton onClick={() => signOut()} variant='ghost' highContrast>
            <FaSignOutAlt />
          </IconButton>
        )}
      </Flex>
    </header>
  )
}
