'use client'
import React, { useCallback, useContext } from 'react'
import { Flex, Select, TextField } from '@radix-ui/themes'
import { FaAdjust, FaMoon, FaRegSun } from 'react-icons/fa'
import { FaSignOutAlt } from "react-icons/fa";
import { useDebounceEdit } from '@/hooks/useDebounceEdit'
import { fetcher } from '@/lib/fetch'
import { ChatContext, useTheme } from '.'

const SettingsInputs = ({ className }: { className?: string }) => {
    const { theme, setTheme } = useTheme()

    const { user } = useContext(ChatContext)

    const remoteUpdateName = useCallback((value: string) => {
        if (!user?.userId) return
        fetcher(`/api/user/profile?userid=${user?.userId}`, 'PATCH', {
            set: {
                name: value
            }
        })
    }, [user?.userId])

    const { value, handleSave } = useDebounceEdit(user?.name || '', remoteUpdateName)
    return (
        <Flex align="center" gap="3" className={className}>
            Name:
            <TextField.Root value={value} placeholder="Name" onChange={(e) => {
                handleSave(e.target.value)
            }} />
            {/* Uncomment the lines below for dark mode light mode functionality */}
            {/* <Select.Root value={theme} onValueChange={setTheme}>
                <Select.Trigger radius="full" />
                <Select.Content>
                    <Select.Item value="light">
                        <FaRegSun />
                    </Select.Item>
                    <Select.Item value="dark">
                        <FaMoon />
                    </Select.Item>
                    <Select.Item value="system">
                        <FaAdjust />
                    </Select.Item>
                </Select.Content>
            </Select.Root> */}
        </Flex>
    )
}

export default SettingsInputs